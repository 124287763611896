/* ========================================
    
    variables
        変数

======================================== */

//　幅・大きさ
$base-fontsize: 16px;
$sp-base-fontsize: 14px;

//　色関連
$c_main: #03924E;
$c_black: #000000;
$c_gray: #EEEEEE;
$c_gray2: #D8D8D8;
$c_gray3: #EBF1F0;
$c_gray4: #F7F7F7;
$c_gray5: #A0A0A0;
$c_gray6: #E9EEF3;
$c_white: #FFFFFF;
$c_red: #E41D49;
$c_yellow: #FFFF00;
$c_blue: #3B7493;

//　メディアクエリ関連
$pc: 1260px;
$tab-max: 1259px;
$tab-min: 980px;
$sp: 979px;
