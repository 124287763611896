/* ========================================
    
    common
        様々なページで使うスタイル

======================================== */

.skew-i{
	@include skew-i;
	
	&_pc{
		@include pc{
			@include skew-i;
		}
	}
	
	&_tab{
		@include pc-only{
			@include skew-i;
		}
		
		@include tab-only{
			@include skew-i2;
		}
	}	
}

.title-wrap{
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	
	&_tech{
		background: url("../images/title/title_tech.png") top center no-repeat, url("../images/title/title_bg.jpg") top left repeat-x;
		
		@include sp{
			background-size: 625px 88px,470px 88px;
		}
	}
	
	&_embed-2{
		background: url("../images/title/title_embed-2.png") top center no-repeat, url("../images/title/title_bg.jpg") top left repeat-x;
		
		@include sp{
			background-size: 625px 88px,470px 88px;
		}
	}	
	
	&_oem{
		background: url("../images/title/title_oem.png") top center no-repeat, url("../images/title/title_bg.jpg") top left repeat-x;
		
		@include sp{
			background-size: 625px 88px,470px 88px;
		}
	}	
	
	&_prod{
		background: url("../images/title/title_prod.png") top center no-repeat, url("../images/title/title_bg.jpg") top left repeat-x;
		
		@include sp{
			background-size: 625px 88px,470px 88px;
		}
	}
	
	&_other{
		background: url("../images/title/title_other.png") top center no-repeat, url("../images/title/title_bg.jpg") top left repeat-x;
		
		@include sp{
			background-size: 625px 88px,470px 88px;
		}
	}			
	
	@include pc{
		height: 179px;
	}
	
	@include sp{
		height: 88px;
	}	
}

.pan{
	position: absolute;
	top: 18px;
	left: 20px;
	display: flex;
	
	&__list{
		color: $c_white;
		font-size: 13px;
		
		&:not(:last-child)::after{
			content: ">";
			margin: 0 7px;
		}
	}
}

.title{
	color: $c_white;
	
	&_r{
		@include font-e;
		position: absolute;
		bottom: 0;
		right: 0;
		font-size: 65px;
		font-style: italic;
		color: $c_white;
		opacity: 0.2;
		line-height: 1;
	}
	
	@include pc{
		font-size: 48px;
	}
	
	@include sp{
		font-size: 31px;
	}	
}

.contents{
	&-c{
		@include pc{
			@include contentWidth(806px);
			padding-bottom: 200px;
		}

		@include sp{
			padding: 0 20px 75px;
		}
	}
	
	&_l{
		@include pc{
			width: 806px;
			margin-right: 58px;
		}
		
		@include sp{
			padding: 0 20px;
		}
	}
	
	&_r{
		@include pc{
			width: 280px;
			padding-top: 60px;
		}
		
		@include sp{
			padding: 0 20px;
		}
	}	
	
	@include pc{
		@include contentWidth(1144px);
		display: flex;
		padding-bottom: 200px;
	}
	
	@include sp{
		padding-bottom: 75px;
	}
}

.h1_under{
	position: relative;
	font-weight: 500;
	color: $c_blue;
	line-height: 1.4;
	
	&::before{
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		background: $c_red;
		
		@include pc{
			width: 2px;
			height: calc(100% + 20px);
		}
		
		@include sp{
			width: 2px;
			height: calc(100% + 20px);
		}		
	}
	
	@include pc{
		font-size: 36px;
		padding-top: 60px;
		padding-left: 30px;
		margin-bottom: 50px;
	}
	
	@include sp{
		font-size: 24px;
		padding-top: 30px;
		padding-left: 15px;
		margin-bottom: 25px;
	}
}

.h2_under{
	font-weight: 500;
	color: $c_white;
	line-height: 1.4;
	background: url("../images/common/h2_bg.jpg") center center no-repeat;
	text-align: center;
	
	&-archive{
		font-weight: 500;
		color: $c_white;
		line-height: 1.4;
		background: url("../images/common/h2_bg.jpg") center center no-repeat;
		margin-bottom: 10px;

		@include pc{
			font-size: 22px;
			padding: 20px;
			margin-top: 80px;
		}

		@include sp{
			font-size: 19px;
			padding: 10px;
			margin-top: 30px;
		}
	}
	
	@include pc{
		font-size: 22px;
		padding: 20px;
		margin-bottom: 30px;
		margin-top: 80px;
	}
	
	@include sp{
		font-size: 19px;
		padding: 10px;
		margin-bottom: 15px;
		margin-top: 30px;
	}
}

.h3_under{
	font-weight: 500;
	color: $c_blue;
	line-height: 1.4;
	border-bottom: 1px solid $c_blue;
	padding-bottom: 10px;
	
	@include pc{
		font-size: 22px;
		margin-bottom: 30px;
		margin-top: 60px;
	}
	
	@include sp{
		font-size: 19px;
		margin-bottom: 15px;
		margin-top: 30px;
	}
}

.h4_under{
	font-weight: 500;
	line-height: 1.4;
	border-bottom: 1px dashed $c_blue;
	color: $c_blue;
	padding-bottom: 7px;
	display: inline-block;
	
	@include pc{
		font-size: 16px;
		margin-bottom: 20px;
		margin-top: 20px;
	}
	
	@include sp{
		font-size: 16px;
		margin-bottom: 15px;
		margin-top: 10px;
	}
}

.txt{
	margin-bottom: 1em;
	
	@include pc{
		font-size: $base-fontsize;
	}
	
	@include sp{
		font-size: $sp-base-fontsize;
	}	
}

.ol-01{
	margin-bottom: 2em;
	list-style: decimal inside;
	
	li {
		margin-bottom: 1em;
	}
	
	@include pc{
		font-size: $base-fontsize;
	}
	
	@include sp{
		font-size: $sp-base-fontsize;
	}	
}

.red-deco{
	margin: 2em 0;
	
	&__list{
		background: url("../images/common/deco-red-line.svg") left top 10px no-repeat;
		margin-bottom: 1em;
		
		@include pc{
			font-size: $base-fontsize;
			padding-left: 30px;
			background-size: 10px 5px;
		}
		
		@include sp{
			font-size: $sp-base-fontsize;
			padding-left: 14px;
			background-size: 6px 3px;
		}		
	}
}

.imgwrap{
	margin: 30px 0;
	text-align: center;
}

.max-width{
	max-width: 100%;
}

.table-01{
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 20px;
	word-break: break-all;
	
	th,td{
		border: 1px solid $c_gray2;
		
		@include pc{
			padding: 20px;
		}
		
		@include sp{
			padding: 10px;
		}		
	}
	
	th{
		background: $c_gray6;
		font-weight: normal;
		border-bottom: 2px solid $c_blue;
	}
	
	td{
		vertical-align: top;
		
		&:first-child{
			background: $c_gray4;
			// @include pc{
			//	background: $c_gray4;
			// }
			
			// @include sp{
			// 	background: $c_gray3;
			// 	border-bottom: 2px solid $c_main;
			//}
		}
	}
	
	@include pc{
		font-size: 14px;
	}

	@include sp{
		font-size: 13px;
	}		
}

.table-02{
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 20px;
	word-break: break-all;
	
	th,td{
		border: 1px solid $c_gray2;
		
		@include pc{
			padding: 20px;
		}
		
		@include sp{
			padding: 10px;
		}		
	}
	
	th{
		background: $c_gray6;
		font-weight: normal;
		border-bottom: 2px solid $c_blue;
	}
	
	td{
		vertical-align: top;
	}
	
	@include pc{
		font-size: 14px;
	}

	@include sp{
		font-size: 13px;
	}		
}

.table-03{
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 20px;
	word-break: break-all;
	
	th,td{
		border: 1px solid $c_gray2;
		
		@include pc{
			padding: 20px 10px;
		}
		
		@include sp{
			padding: 10px;
		}		
	}
	
	th{
		background: $c_gray6;
		font-weight: normal;
		border-bottom: 2px solid $c_blue;
	}
	
	td{
		vertical-align: top;
	}
	
	@include pc{
		font-size: 12px;
	}

	@include sp{
		font-size: 12px;
	}		
}

.bg-w {
	background: #FFFFFF !important;
}

.l-navi{
	background: $c_gray;
	padding: 25px;
	position: relative;
	z-index: 50;
	
	&::after{
		content: "";
		position: absolute;
		bottom: -22px;
		right: -80px;
		background: $c_gray;
		width: 400px;
		height: 200px;
		transform: rotate(-10deg);
		z-index: -1;
	}
	
	&-title{
		border-top: 2px solid $c_main;
		padding: 20px;
		text-align: center;
		font-size: 18px;
	}
	
	&__ul{
		padding-bottom: 15px;
	}
	
	&__list_01{
		color: $c_main;
		font-size: 18px;
		font-weight: 500;
		padding-bottom: 10px;
		padding-right: 30px;
		border-bottom: 1px solid $c_main;
		margin-bottom: 15px;
		background: url("../images/common/arrow-g-r.svg") right top 10px no-repeat;
		background-size: 5px 8px;
	}
	
	&__list_02{
		font-size: 13px;
		padding-left: 15px;
		margin-bottom: 1em;
		
		&:not(.current){
			background: url("../images/common/arrow-b-r.svg") left top 6px no-repeat;
			background-size: 5px 8px;
		}
		
		&.current,&:hover{
			color: $c_main;
			font-weight: 500;
			background: url("../images/common/arrow-g-r.svg") left top 6px no-repeat;
			background-size: 5px 8px;
		}
	}	
	
	&__list_03{
		@extend .l-navi__list_02;
		margin-left: 1em;
	}	
}

.l-contact{
	background: $c_main;
	padding: 70px 20px 1px;
	width: 256px;
	margin: auto;
	
	&__title{
		color: $c_white;
		background: url("../images/common/deco-contact.svg") top center no-repeat;
		background-size: 25px;
		font-size: 18px;
		padding-top: 33px;
		text-align: center;
		margin-bottom: 25px;
	}
	
	&__m{
		color: $c_white;
		font-size: 13px;
		border-bottom: 1px solid $c_white;
		padding-bottom: 6px;
		margin-bottom: 10px;
	}
	
	&__s{
		color: $c_white;
		font-size: 13px;
		margin-bottom: 5px;
		
		&_b{
			font-weight: 500;
			font-size: 16px;
		}
		
	}
}

.btn-01{
	position: relative;
	display: block;
	background: $c_main;
	color: $c_white;
	text-align: center;
	margin: 2em auto 4em;
	
	&::after{
		content: "";
		position: absolute;
		background: url("../images/common/btn-arrow.svg") no-repeat;
		
		@include pc{
			right: -10px;
			bottom: -10px;
			width: 87px;
			height: 53px;
			background-size: 87px 53px;
		}
		
		@include sp{
			right: -15px;
			bottom: -10px;
			width: 38px;
			height: 26px;
			background-size: 38px 26px;
		}		
	}
	
	@include pc{
		padding: 20px;
		width: 486px;
		font-size: 20px;
	}
	
	@include sp{
		padding: 12px;
		font-size: 14px;
	}	
}

.btn-02{
	color: $c_main;
	display: block;
	background: $c_white;
	padding: 12px;
	text-align: center;
	font-size: 15px;
	margin: 20px 0;
	
	&::before{
		content: "";
		background: url("../images/common/arrow-g-r.svg") no-repeat;
		background-size: 6px 11px;
		width: 6px;
		height: 11px;
		display: inline-block;
		margin-right: 8px;
	}
}

.flex-l{
	@include pc{
		display: flex;
		flex-wrap: wrap;
	}
}

.flex-c{
	@include pc{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.flex-common{
	.txt, .ol-01{
		@include pc{
			width: 586px;
			margin-right: 20px;
		}
		
		@include sp{
			margin-bottom: 1em;
		}		
	}
	
	&__img{
		img {
			@include pc{
				width: 100%;
				height: auto;
			}
		}
		
		@include pc{
			width: 200px;
		}
		
		@include sp{
			text-align: center;
		}
	}
	
	@include pc{
		display: flex;
	}
}

.cat-btn01{
	display: block;
	background: url("../images/common/h2_bg.jpg") center center no-repeat;
	
	&:nth-child(2n){
		@include pc{
			margin-left: 22px;
		}
	}
	
	&__title{
		font-weight: 500;
		color: $c_white;
		background: url("../images/common/arrow-y-r.svg") right 10px center no-repeat;
		background-size: 9px 15px;
		
		@include pc{
			font-size: 22px;
			min-height: 88px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px;
		}
		
		@include sp{
			padding: 10px;
			font-size: 17px;
		}		
	}
	
	&__w{
		background: $c_white;
		padding: 10px;
		
		@include pc{
			min-height: 100px;
		}
	}
	
	&__txt{
		@include pc{
			font-size: 16px;
		}
		
		@include sp{
			font-size: 15px;
		}		
	}
	
	@include pc{
		padding: 8px;
		width: 392px;
		margin-bottom: 25px;
	}
	
	@include sp{
		padding: 4px;
		margin-bottom: 20px;
	}
}

.cat-btn02{
	display: block;
	background: url("../images/common/arrow-y-r.svg") right 10px center no-repeat, url("../images/common/h2_bg.jpg") center center no-repeat;
	background-size: 9px 15px, auto;
	margin: 0 auto 4em;
	color: $c_white;
	font-weight: 500;
	
	@include pc{
		width: 392px;
		padding: 30px;
		text-align: center;
		font-size: 22px;
	}
	
	@include sp{
		padding: 15px;
		font-size: 17px;
	}	
}

.overflow{
	position: relative;
	width: 100%;
	overflow-x: hidden;
}

.bold{
	font-weight: bold;
}

.txtlink{
	text-decoration: underline;
	color: $c_main;
}

.new {
	color: $c_red;
	font-size: 65%;
	margin-left: 10px;
}

.c-red {
	color: $c_red;
}

.block-gray {
	background: $c_gray;
	padding: 20px;
	text-align: center;
}

.pager01{
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 60px;
	
	&__prev{
		background: url("../images/common/arrow-g-r.svg") no-repeat;
		width: 12px;
		height: 20px;
		background-size: 12px 20px;
		transform: scale(-1, 1);
		margin-right: 25px;
	}
	
	&__next{
		background: url("../images/common/arrow-g-r.svg") no-repeat;
		width: 12px;
		height: 20px;
		background-size: 12px 20px;
		margin-left: 25px;
	}	
	
	&__num{
		width: 37px;
		line-height: 37px;
		font-size: 20px;
		text-align: center;
		border: 1px solid $c_main;
		color: $c_main;
		
		&:not(:last-child){
			margin-right: 15px;
		}
		
		&.current{
			background: $c_main;
			color: $c_white;
		}
		
		&-wrap{
			display: flex;
			align-items: center;
		}
	}
}

.archive-date{
	color: $c_blue;
	
	@include pc{
		font-size: 14px;
		margin-bottom: 15px;
	}
	
	@include sp{
		font-size: 12px;	
		margin-bottom: 15px;
	}	
}

.archive-txt{
	p{
		margin-bottom: 1em;
	}
	
	@include pc{
		font-size: $base-fontsize;
	}
	
	@include sp{
		font-size: $sp-base-fontsize;
	}	
}

.qanda{
	&__q{
		display: table;
		color: $c_main;
		width: 100%;
		cursor: pointer;
		
		&::before{
			content: "Q.";
			font-weight: bold;
			display: table-cell;
			vertical-align: top;
			width: 1.8em;
		}
		
		&:not(:first-child){
			margin-top: 30px;
		}
		
		&:last-child{
			margin-bottom: 50px;
		}
		
		&--txt{
			display: table-cell;
			vertical-align: top;
		}
		
		@include pc{
			font-size: 18px;
		}
		
		@include sp{
			font-size: 16px;
		}		
	}
	
	&__a{
		display: table;
		margin-top: 10px;
		background: $c_gray;
		width: 100%;
		display: none;
		
		&::before{
			content: "A.";
			font-weight: bold;
			display: table-cell;
			vertical-align: top;
			padding: 20px 0 20px 20px;
			color: $c_red;
			width: 1.8em;
		}
		
		&--txt{
			display: table-cell;
			vertical-align: top;
			padding: 20px 20px 20px 0;
		}
		
		@include pc{
			font-size: 18px;
		}
		
		@include sp{
			font-size: 16px;
		}		
	}	
}

/* フォーム */
.form01{
	width: 100%;
	
	td{
		vertical-align: top;
		
		@include pc{
			padding: 15px 0;
		}
		
		@include sp{
			padding: 8px 0;
			display: block;
		}
	}
	
	td:first-child{
		width: 13em;
	}
	
	@include pc{
		font-size: 16px;
	}
	
	@include sp{
		font-size: 15px;
	}
}

.wpcf7-text{
    padding: 8px;
    border: none;
    border-radius: 0;
    outline: none;
    background: $c_gray;	
}

.wpcf7-textarea{
    appearance: none;
    resize: none;
    padding: 8px;
    border: 0;
    outline: none;
    background: $c_gray;
	width: 100%;
}

.wpcf7-submit{
	@include submitReset;
	display: inline-block;
	background: $c_main;
	padding: 15px 40px;
	text-align: center;
	color: $c_white;
	
	& + &{
		margin-left: 15px;
	}
	
	@include pc{
		font-size: 20px;
	}

	@include sp{
		font-size: 17px;
	}	
}

.wpcf7-list-item{
	display: inline-block;
	margin-right: 10px;
}

.mt15{
	display: block;
	margin-top: 15px;
}

.mt40{
	margin-top: 40px;
}

.mr15{
	margin-right: 15px;
}

input[type="radio"] {
	appearance: radio;
	margin-right: 6px;
}

input[type="checkbox"] {
	appearance: checkbox;
	margin-right: 4px;
}

.form-btn-wrap{
	margin: 30px 0;
	text-align: center;
}

.form-btn-wrap02{
	margin-top: 70px;
	text-align: center;
}

.column2{
	border: 1px solid $c_main;
	
	&:nth-child(2n){
		@include pc{
			margin-left: 44px;
		}
	}
	
	&__title{
		text-align: center;
		color: $c_main;
		font-weight: bold;
		margin-bottom: 20px;
		
		@include pc{
			font-size: 22px;
		}
		
		@include sp{
			font-size: 19px;
		}
	}
	
	&-wrap{
		@include pc{
			display: flex;
			margin-bottom: 30px;
		}
	}
	
	@include pc{
		padding: 30px;
		width: 381px;
	}
	
	@include sp{
		padding: 20px;
		margin-bottom: 20px;
	}
}

.sub-txt{
	display: block;
	border: 1px solid $c_gray5;
	padding: 15px;
	font-size: 13px;
	margin: 1.5em 0;
	word-break: break-all;
}

.btn-03{
	display: inline-block;
	background: $c_main;
	padding: 15px 40px;
	text-align: center;
	color: $c_white;
	
	& + &{
		margin-left: 15px;
	}
	
	@include pc{
		font-size: 20px;
	}

	@include sp{
		font-size: 17px;
	}	
}

.cap-txt{
	display: block;
	font-size: 13px;
	margin-top: 8px;
	text-align: center;
}

.massege-img{
	@include pc{
		float: left;
		margin-right: 15px;
		margin-bottom: 15px;
	}

	@include sp{
		display: block;
		margin: auto;
		margin-bottom: 20px;
	}
}