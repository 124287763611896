/* -----------------------------------------------
タグ初期化
------------------------------------------------- */
*{ margin:0; padding:0; line-height: 1.6; box-sizing:border-box; }
html{ font-size:10px; font-weight:400; color:#000000; }
img { border:none; vertical-align:top; }
a { color:inherit; text-decoration:none; outline:none; }
a:focus, *:focus { outline:none; }
input, button, textarea{ outline:none; -webkit-appearance:none; -moz-appearance:none; appearance:none; }
ul{ list-style: none; }
h1, h2, h3, h4, h5, h6{ font-weight: normal; }

/* -----------------------------------------------

------------------------------------------------- */