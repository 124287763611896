/* ========================================
    
   top
        トップページのスタイル

======================================== */

.top-r-contents{
	&::before{
		@include sp{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 35%);
		}
	}

	@include pc{
		position: absolute;
		right: 0;
		z-index: 1500;
		top: 0;
		overflow: hidden;
	}
	
	@include pc-only{
		@include skew-w;
		width: 980px;
	}
	
	@include tab-only{
		@include skew-w2;
		width: 458px;
	}

	@include sp{
		position: relative;
	}
}

.top-contents-main{
	&-box{
		&__title{
			@include font-e;
			font-style: italic;	
			color: $c_white;
			opacity: 0.4;
			line-height: 1;
			
			@include pc-only{
				font-size: 86px;
				margin-bottom: 10px;
			}
			
			@include tab-only{
				font-size: 81px;
				margin-bottom: 10px;
			}
			
			@include sp{
				padding-left: 20px;
				margin-right: 10px;
				display: inline-block;
				font-size: 50px;
			}			
		}
		
		&__subtitle{
			color: $c_white;
			
			@include pc{
				font-size: 17px;
				margin-bottom: 40px;
			}
			
			@include tab-only {
				text-align: center;
			}
			
			@include sp{
				font-size: 13px;
				display: inline-block;
				margin-bottom: 30px;
			}
		}
		
		&__flex{
			@include sp{
				display: flex;
				flex-wrap: wrap;
			}
		}

		&__item{
			@include pc-only{
				display: flex;
				align-items: center;
				margin-bottom: 10px;
			}
			
			@include tab-only{
				text-align: center;
			}

			@include sp{
				width: 50%;
				margin-bottom: 20px;
			}
		}

		&__img{
			@include pc-only{
				width: 112px;
				margin-right: 20px;
			}
			
			@include tab-only{
				margin-bottom: 20px;
			}

			@include sp{
				text-align: center;
				margin-bottom: 8px;
			}
		}
		
		&__photo{
			@include pc{
				width: 112px;
				height: 91px;
			}

			@include sp{
				width: 109px;
				height: 89px;
			}
		}		
		
		&__txt{
			color: $c_white;
			
			@include pc{
				font-size: 13px;
			}
			
			@include pc-only{
				width: 136px;
			}
			
			@include tab-only{
				padding: 0 75px;
				margin-bottom: 30px;
			}
			
			@include sp{
				margin: auto;
				width: 148px;
				font-size: 14px;
			}
		}
		
		@include pc{
			position: absolute;
			height: 712px;
			background: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 60%);
			left: 0;
			top: 0;
			width: 93.1%;
		}
		
		@include pc-only{
			padding-left: 580px;
			padding-top: 60px;
		}
		
		@include tab-only{
			padding-left: 140px;
			padding-top: 60px;
		}

		@include sp{
			position: relative;
			z-index: 10;
		}
	}
	
	&__img{
		position: absolute;
		top: 0;
		left: 0;
	}
	
	@include pc{
		position: relative;
		height: 712px;
	}
	
	@include sp{
		padding-top: 90px;
		background: $c_black url("../images/top/main-v-sp.jpg") no-repeat;
		background-size: 100% auto;
		margin-bottom: 50px;
	}
}

.top-tab-nav{
	background: $c_main;
	padding-left: 370px;
	height: 113px;
	padding-top: 35px;
}

.slide{
	position: relative;
	z-index: 10;
	background: $c_black;

	&::before{
		@include sp{
			content: "";
			position: absolute;
			bottom: 0;
			left: -30px;
			transform-origin: top left;
			z-index: -1;
			background: $c_black;
			height: 300px;
			width: 130%;
			transform: rotate(10deg);
		}		
	}
	
	&-item{
		position: absolute;
		top: 0;
		
		&::before{
			@include pc{
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				background: linear-gradient(to right, rgba(0, 0, 0, 0) 90%, rgba(0, 0, 0, 1));
				z-index: 10;
			}

			@include sp{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 100%);
				z-index: 10;
			}
		}
		
		&:not(.active){
			// z-index: 5;
			opacity: 0;
		}
		
		&.active{
			// z-index: 10;
			opacity: 1;
		}
		
		&.transition{
			transition: opacity 2s;
		}
		
		&__wrap{
			z-index: 40;

			@include pc{
				position: absolute;
				width: 100%;
				left: 0;
				bottom: 0;
				padding-top: 100px;
				padding-bottom: 50px;
				padding-left: 70px;
				background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 100%);
			}
			
			@include tab-only {
				padding-right: 30vw;
			}
			
			@include sp{
				position: relative;
				padding: 160px 25px 20px;
			}			
		}
		
		&__img{
			&-wrap{
				&::before{
					@include sp{
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 40%);
						z-index: 5;
					}
				}

				@include sp{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
				}
			}

			@include sp{
				width: 100%;
			}
		}
		
		&__txt01{
			color: $c_yellow;
			
			&_big{
				@include pc{
					font-size: 48px;
				}				
				
				@include sp{
					font-size: 31px;
				}
			}
			
			@include pc{
				font-size: 38px;
				margin-bottom: 15px;
			}			
			
			@include sp{
				font-size: 28px;
				margin-bottom: 15px;
			}
		}
		
		&__txt02{
			color: $c_white;
			
			@include pc{
				font-size: 18px;
			}			
			
			@include sp{
				font-size: 15px;
			}
		}
		
		
		@include pc-only{
			left: 0;
			width: 1310px;
		}
		
		@include tab-only{
			left: 0;
			width: 100%;
		}
		
		@include sp{
			right: 0;
			width: 100%;
			overflow: hidden;
		}		
	}
	
	&-control{
		position: absolute;
		bottom: 15px;
		right: 792px;
		z-index: 500;
		display: flex;
		
		&__button{
			width: 10px;
			height: 10px;
			border-radius: 50%;
			
			&:not(:first-child){
				margin-left: 20px;
			}
			
			&:not(.active){
				background: $c_white;
			}
			
			&.active{
				background: $c_yellow;
			}			
		}
	}
	
	&-dir{
		position: absolute;
		bottom: -12vw;
		right: 20px;
		width: 57px;
		height: 57px;
		z-index: 55;
		
		&__prev{
			@include buttonReset;
			position: absolute;
			top: 0;
			left: 0;
			width: 20px;
			height: 37px;
			background: url("../images/common/arrow-w-r.svg") no-repeat;
			background-size: 20px 37px;
			transform: scale(-1, 1);
		}
		
		&__next{
			@include buttonReset;
			position: absolute;
			bottom : 0;
			right: 0;
			width: 20px;
			height: 37px;
			background: url("../images/common/arrow-w-r.svg") no-repeat;
			background-size: 20px 37px;
		}
	}
	
	@include pc{
		height: 711px;
	}

	@include sp{
		padding-top: 104.8%;
	}		
}

.ticker{
	display: flex;
	
	&__title{
		@include font-e;
		font-style: italic;
		color: $c_main;
		line-height: 1;
		
		@include pc{
			font-size: 50px;
			width: 130px;
		}
		
		@include sp{
			font-size: 25px;
			width: 65px;
		}
	}
	
	&-wrap{
		overflow: hidden;
		
		@include pc{
			width: calc(100% - 130px);
		}
		
		@include sp{
			width: calc(100% - 65px);
		}
	}
	
	&-contents{
		display: flex;
	}
	
	&__item{
		display: block;
		width: 100%;
		
		@include pc{
			font-size: 15px;
		}
		
		@include sp{
			font-size: 14px;
		}
	}
	
	@include pc{
		width: 95%;
		min-width: 1090px;
		margin: auto;
		border-bottom: 1px solid $c_main;
		padding-bottom: 15px;
		padding-top: 15px;
		margin-bottom: 80px;
		align-items: baseline;
	}
	
	@include sp{
		position: absolute;
		top: 45px;
		left: 0;
		width: 100%;
		background: $c_gray;
		padding: 15px 10px;
		z-index: 200;
		align-items: center;
	}
}

.top-cat{
	&-box{
		&:not(:first-child){
			@include pc{
				margin-left: 90px;
			}
		}
		
		&__img{
			display: block;
			position: relative;
			
			@include pc{
				margin-bottom: 40px;
			}
			
			@include sp{
				width: 257px;
				margin: 0 auto 20px;
			}			
		}
		
		&__button{
			overflow: hidden;
			position: absolute;
			z-index: 50;
			bottom: -10px;
			right: -10px;
			
			&::before{
				content: "";
				position: absolute;
				background: $c_main;
				transform: rotate(35deg);
				width: 300px;
				height: 300px;
				transform-origin: right center;
				top: 0;
				left: 0;
				z-index: -1;
			}
			
			&-img{
				@include pc{
					width: 15px;
					height: 29px;
				}
				
				@include sp{
					width: 14px;
					height: 26px;
				}
			}
			
			@include pc{
				padding-top: 12px;
				padding-left: 48px;
				width: 87px;
				height: 53px;
			}
			
			@include sp{
				padding-top: 12px;
				padding-left: 44px;
				width: 76px;
				height: 47px;
			}
		}
		
		&__txt{
			@include pc{
				font-size: 16px;
			}
			
			@include sp{
				font-size: 15px;
				width: 90%;
				margin: auto;
			}			
		}
		
		@include pc{
			width: 408px;
		}
		
		@include pc-only {
			margin-bottom: 70px;
		}
		
		@include tab-only {
			margin-bottom: 50px;
		}		
		
		@include sp{
			margin-bottom: 35px;
		}		
	}
	
	@include pc-only{
		display: flex;
		width: 1404px;
		margin: auto;
	}
	
	@include tab-only {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.h2_top01{
	text-align: center;
	
	@include pc{
		font-size: 26px;
		margin-bottom: 30px;
	}
	
	@include sp{
		font-size: 24px;
		margin-bottom: 15px;
	}
}

.h2_top02{
	color: $c_white;
	
	@include pc{
		width: 224px;
		background: url("../images/top/icon-tech.svg") left center no-repeat;
		background-size: 55px 54px;
		font-size: 26px;
		padding: 10px 0 10px 70px;
	}
	
	@include sp{
		background: url("../images/top/icon-tech.svg") left center no-repeat, url("../images/common/arrow-w-r.svg") right center no-repeat;
		background-size: 41px, 14px 26px;
		padding-left: 50px;
		font-size: 24px;
		margin-bottom: 15px;
	}
}

.top-links{
	&__tech{
		display: block;
		background: $c_main;
		
		&-txt{
			color: $c_white;
			
			@include pc{
				width: calc(100% - 224px);
				font-size: 16px;
				padding-right: 40px;
				background: url("../images/common/arrow-w-r.svg") right center no-repeat;
				background-size: 15px 29px;
			}
			
			@include sp{
				font-size: 14px;
			}
		}
		
		@include pc{
			width: 682px;
			padding: 20px 30px;
			display: flex;
			align-items: center;
		}
		
		@include tab-only {
			margin: 0 auto 40px;
		}
		
		@include sp{
			padding: 15px;
			width: 90%;
			margin: 0 auto 25px;
		}
	}
	
	&-box{
		display: flex;
		
		&__link{
			display: block;
			width: 50%;
			border-top: 2px solid $c_main;
			border-bottom: 2px solid $c_main;
			border-left: 2px solid $c_main;
			
			&:last-child{
				border-right: 2px solid $c_main;
			}
			
			@include pc{
				display: flex;
				align-items: center;
				justify-content: center;
			}
			
			@include tab-only {
				height: 142px;
			}
			
			@include sp{
				padding: 15px 0;
				text-align: center;
			}
		}
		
		&__txt{
			color: $c_main;
			font-weight: 500;
			
			@include pc{
				font-size: 22px;
			}
			
			@include sp{
				font-size: 18px;
				margin-bottom: 10px;
			}
		}
		
		&__img{
			@include pc{
				width: 120px;
				height: 70px;
			}
			
			@include sp{
				width: 68px;
				height: 40px;
			}
		}
		
		@include pc{
			width: 504px;
		}
		
		@include pc-only {
			margin-left: 34px;
		}
		
		@include tab-only {
			margin: auto;
		}
		
		@include sp{
			width: 90%;
			margin: 0 auto 40px;
		}
	}
	
	@include pc{
		margin: 0 auto 80px;
	}
	
	@include pc-only {
		display: flex;
		width: 1220px;
	}
}

.news{
	&__title{
		@include font-e;
		font-style: italic;
		color: $c_main;
		text-align: center;
		border-bottom: 1px solid $c_main;
		line-height: 1;
		
		@include pc{
			font-size: 50px;
			padding-bottom: 30px;
			margin-bottom: 30px;
		}

		@include sp{
			font-size: 35px;
			padding-bottom: 10px;
			margin-bottom: 25px;
		}
	}
	
	&-ul{
		display: table;
		width: 100%;
		margin-bottom: 20px;
	}
	
	&__date,&__txt{
		vertical-align: top;
		display: table-cell;
		
		@include pc{
			font-size: 15px;
		}
		
		@include sp{
			font-size: 14px;
		}
	}
	
	&__date{
		width: 10em;
	}
	
	@include pc{
		width: 650px;
		margin: 0 auto 70px;
	}
	
	@include sp{
		width: 90%;
		margin: 0 auto 40px;
	}
}