/* ========================================
    
    base
        タグに直接かけるスタイル

    INDEX
        - html,body

======================================== */

// Noto Sans JP
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500&display=swap');
// Barlow Condensed
@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed&display=swap');

//　html,body
html, body {
	@include transition(left, 0.5s);
	width: 100%;
	min-height: 100%;
}

body{
	font-family: 'Noto Sans JP', sans-serif;
	color: #000000;
}
