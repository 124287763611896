/* ========================================
    
    mixin

    INDEX
		- メディアクエリ
		- ブロック要素の中央寄せ
		- transition
		- button要素のリセット
		- ボタンのベース
		- hr要素のリセット

======================================== */

//　メディアクエリ
@mixin pc {
	@media (min-width: ($tab-min)) {
		@content;
	}
}

@mixin pc-only {
	@media (min-width: ($pc)) {
		@content;
	}
}

@mixin tab-only {
	@media (max-width: ($tab-max)) and (min-width: ($tab-min)) {
		@content;
	}
}

@mixin sp {
	@media (max-width: ($sp)) {
		@content;
	}
}

//　ブロック要素の中央寄せ
@mixin contentWidth($width, $mb:0) {
	width: $width;
	margin: 0 auto $mb;
}

// 英フォント
@mixin font-e {
	font-family: 'Barlow Condensed', sans-serif;
}

//　transition
@mixin transition($property, $time) {
	transition: $property $time;
}

// button要素のリセット
@mixin buttonReset{
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
	appearance: none;	
}

// メニュー斜めwrap
@mixin skew-w{
	transform: skewX(35deg);
	transform-origin: right top;
}

// メニュー斜めwrap
@mixin skew-w2{
	transform: skewX(15deg);
	transform-origin: right top;
}

// メニュー斜めinner
@mixin skew-i{
	transform: skewX(-35deg);
	transform-origin: right top;
}

// メニュー斜めinner
@mixin skew-i2{
	transform: skewX(-15deg);
	transform-origin: right top;
}

// submitのスタイル初期化
@mixin submitReset{
	border-radius: 0;
	-webkit-box-sizing: content-box;
	-webkit-appearance: button;
	appearance: button;
	border: none;
	box-sizing: border-box;
		&::-webkit-search-decoration {
			display: none;
		}
		&::focus {
			outline-offset: -2px;
		}	
}