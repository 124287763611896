/* ========================================
    
    parts
        付けるだけで動くスタイルのパーツ

    INDEX
		- font
		- format 記事詳細用
        - element
        - layout
        - color
        - margin, padding

======================================== */

/* ========================================
    element
======================================== */

// PCサイズ以外で非表示
.pc-elem {
	@include sp {
		display: none !important;
	}
}

.pc-only-elem {
	@include tab-only {
		display: none !important;
	}	
	
	@include sp {
		display: none !important;
	}
}

.tab-only-elem {
	@include pc-only {
		display: none !important;
	}	
	
	@include sp {
		display: none !important;
	}
}

.pc-sp-elem {
	@include tab-only {
		display: none !important;
	}	
}

.sp-elem {
	@include pc {
		display: none !important;
	}
}

.sp-only-elem {
	@include pc-only {
		display: none !important;
	}
}

/* ========================================
    layout
======================================== */
.al-c {
    text-align: center !important;
}

.al-r {
    text-align: right !important;
}

.al-l {
    text-align: left !important;
}

.fr {
    float: right !important;
}

.fl {
    float: left !important;
}

/* ========================================
    color
======================================== */

/* ========================================
    margin, padding
======================================== */
// 0〜120まで5px刻み

@for $i from 0 through 6 {
    $tmp: $i*10;
  
    // margin
    .mt-#{$tmp} {
        margin-top: #{$tmp}px !important;
    }
  
    .mr-#{$tmp} {
        margin-right: #{$tmp}px !important;
    }
  
    .mb-#{$tmp} {
        margin-bottom: #{$tmp}px !important;
    }
  
    .ml-#{$tmp} {
        margin-left: #{$tmp}px !important;
    }
  
    // padding
    .pt-#{$tmp} {
        padding-top: #{$tmp}px !important;
    }
  
    .pr-#{$tmp} {
        padding-right: #{$tmp}px !important;
    }
  
    .pb-#{$tmp} {
        padding-bottom: #{$tmp}px !important;
    }
  
    .pl-#{$tmp} {
        padding-left: #{$tmp}px !important;
    }
}



