/* ========================================
    
    gnav
        グロナビ・ヘッダー関連

======================================== */

.header{
	background: $c_white;
	
	&__logo{
		&-img{
			@include pc{
				width: 284px;
				height: 71px;
			}
			
			@include sp{
				width: 170x;
				height: 43px;
			}					
		}
		
		@include pc{
			padding-top: 3px;
			padding-left: 15px;
		}
	}
	
	&-nav{
		@include skew-w;
		@include transition(top, 1s);
		position: fixed;
		right: 0;
		width: 760px;
		z-index: 2500;
		
		&.js-fadein:not(.active), &.js-fadein-02:not(.active){
			top: -113px;
		}
		
		&.js-fadein.active, &.js-fadein-02.active{
			top: 0;
		}		
		
		&-01,&-02{
			text-align: right;
		}
		
		&-01{
			background: $c_gray;
			height: 48px;
			padding-top: 15px;
			padding-right: 35px;
			
			&__link{
				font-size: 13px;
				margin-right: 30px;
			}
		}

		&-02{
			background: $c_main;
			height: 65px;
			padding-top: 20px;
			padding-right: 45px;
			
			&__link{
				color: $c_white;
				font-size: 18px;
				margin-right: 60px;
				
				&:hover,&.current{
					color: $c_yellow;
				}
			}
		}
		
		&-sp{
			@include transition(transform, 0.5s);
			position: fixed;
			background: $c_gray;
			top: 0;
			right: 0;
			transform: translateX(100%);
			z-index: 2000;
			height: 100%;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
			
			&-top{
				position: relative;
				background: $c_main;
				height: 50px;
			}
			
			&__link_p,&__link_m,&__link_c{
				display: block;
			}
			
			&__link_p{
				padding: 15px 0 15px 20px;
				color: $c_main;
				font-weight: 500;
				font-size: 15px;
				
				&_bd{
					border-top: 1px solid $c_gray2;
				}
				
				&:not(.open){
					background: url("../images/common/plus.svg") right 15px center no-repeat;
					background-size: 11px;
				}
				
				&.open{
					background: url("../images/common/arrow-g-r.svg") right 15px center no-repeat;
					background-size: 5px 10px;
				}				
			}
			
			&__link_m{
				padding: 0 0 15px 45px;
				font-weight: 500;
				font-size: 13px;
				background: url("../images/common/arrow-g-r.svg") right 15px center no-repeat;
				background-size: 5px 10px;
				
				&.js-acd-button:not(.open){
					background: url("../images/common/plus.svg") right 15px center no-repeat;
					background-size: 11px;
				}
				
				&.js-acd-button.open{
					background: url("../images/common/arrow-g-r.svg") right 15px center no-repeat;
					background-size: 5px 10px;
				}	
				
				&::before{
					content: "";
					display: block;
					background: $c_gray2;
					margin-bottom: 15px;
					height: 1px;
				}
			}			
			
			
			&__link_c{
				padding: 0 0 15px 45px;
				font-size: 13px;
				background: url("../images/common/arrow-b-r.svg") right 15px center no-repeat;
				background-size: 5px 10px;
				
				&::before{
					content: "";
					display: block;
					background: $c_gray2;
					margin-bottom: 15px;
					height: 1px;
				}
			}		
			
			&__link_g{
				margin-left: 45px;
			}
			
			&-links{
				display: flex;
				flex-wrap: wrap;
				padding-top: 35px;
				border-top: 1px solid $c_gray2;
				
				&__list{
					width: 50%;
					text-align: center;
					font-size: 13px;
					margin-bottom: 35px;
				}
			}
			
			@include tab-only{
				width: 700px;
			}			
			
			@include sp{
				width: 100%;
			}
			
		}
	}	
	
	@include pc{
		height: 82px;
	}
	
	@include sp{
		height: 45px;
	}	
}

.menu-open{
	@include buttonReset;
	z-index: 500;
	
	&::before{
		@include sp{
			content: "";
			position: absolute;
			top: -43px;
			left: 22px;
			background: $c_main;
			width: 100px;
			height: 100px;
			transform: rotate(-40deg);
		}
	}
	
	&:not(.open){
		right: 0;
	}
	
	&.open{
		right: -75px;
	}
	
	&-bar_top,&-bar_middle,&-bar_bottom{
		position: absolute;
		background: $c_white;
		
		@include tab-only{
			height: 4px;
			width: 44px;
			right: 0;
		}
		
		@include sp{
			height: 2px;
			width: 26px;
			right: 10px;
		}
	}
	
	&-bar_top{
		@include tab-only{
			top: 0px;
		}
		
		@include sp{
			top: 10px;
		}		
	}
	
	&-bar_middle{
		@include tab-only{
			top: 15px;
		}
		
		@include sp{
			top: 19px;
		}			
	}
	
	&-bar_bottom{
		@include tab-only{
			top: 30px;
		}
		
		@include sp{
			top: 28px;
		}			
	}	
	
	@include tab-only{
		position: relative;
		width: 44px;
		height: 36px;
	}
	
	@include sp{
		position: fixed;
		overflow: hidden;
		width: 75px;
		height: 43px;
		top: 7px;
		@include transition(right, 0.2s);
	}
}

.menu-close{
	@include buttonReset;
	position: absolute;
	top: 15px;
	right: 15px;
	width: 17px;
	height: 17px;
	
	&-bar_l,&-bar_r{
		position: absolute;
		width: 26px;
		height: 2px;
		top: 0;
		background: $c_white;
	}
	
	&-bar_l{
		left: 0;
		transform-origin: left top;
		transform: rotate(45deg);
	}
	
	&-bar_r{
		right: 0;
		transform-origin: right top;
		transform: rotate(-45deg);
	}	
}