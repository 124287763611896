/* ========================================
    
    footer
        フッター関連

======================================== */

.footer{
	background: $c_gray;
	
	&__inner{
		@include pc{
			width: 1090px;
			margin: auto;
			padding-top: 50px;
		}
	}
	
	&-3column{
		&__box{
			&:not(:nth-child(3n + 1)){
				@include pc{
					padding-left: 60px;
				}
			}			
			
			&:not(:nth-child(3n + 3)){
				@include pc{
					width: 33.33%;
				}
			}
			
			&:nth-child(3n + 3){
				@include pc{
					width: 33.34%;
				}
			}	
			
			&:nth-child(3n + 2){
				@include pc{
					border-left: 1px solid $c_gray5;
					border-right: 1px solid $c_gray5;
				}
			}				
			
			@include pc{
				padding-bottom: 50px;
			}
		}
		
		@include pc{
			display: flex;
			border-bottom: 1px solid $c_gray5;
		}
	}
	
	&-1column__box{
		@include pc{
			padding-top: 50px;
		}
	}
	
	&__ul_02{
		@include pc{
			display: flex;
			flex-wrap: wrap;
			padding-left: 20px;
			padding-bottom: 50px;
		}
	}
	
	&__ul_03{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		border-top: 1px solid $c_gray5;
		
		@include pc{
			padding: 40px 0;
		}
		
		@include sp{
			padding-top: 40px;
		}
	}	
	
	&__item_01{
		font-weight: 500;
		color: $c_main;
		
		&:not(.open){
			@include sp{
				background: url("../images/common/plus.svg") right 15px center no-repeat;
				background-size: 11px;
			}
		}

		&.open{
			@include sp{
				background: url("../images/common/arrow-g-r.svg") right 15px center no-repeat;
				background-size: 5px 10px;
			}
		}	
		
		&-link{
			@include sp{
				display: block;
				padding: 14px 0;
			}
		}
		
		@include pc{
			margin-bottom: 15px;
			font-size: 16px;
		}
		
		@include sp{
			border-top: 1px solid $c_gray5;
			padding-left: 20px;
			font-size: 15px;
		}		
	}
	
	&__item_02{
		&-link{
			@include sp{
				border-top: 1px solid $c_gray5;
				display: block;
				padding: 14px 0;
			}
		}
		
		@include pc{
			margin-bottom: 8px;
			font-size: 13px;
		}
		
		@include sp{
			background: url("../images/common/arrow-b-r.svg") right 15px center no-repeat;
			background-size: 5px 10px;
			padding-left: 45px;
			font-size: 13px;
		}		
	}	
	
	&__item_03{
		&:not(:nth-child(3n + 3)){
			@include pc{
				width: 33.33%;
			}
		}
		
		&:nth-child(3n){
			@include pc{
				width: 33.34%;
			}
		}		
		
		&-link{
			@include sp{
				border-top: 1px solid $c_gray5;
				display: block;
				padding: 14px 0;
			}
		}
		
		@include pc{
			margin-bottom: 8px;
			font-size: 13px;
			padding-right: 20px;
		}
		
		@include sp{
			background: url("../images/common/arrow-b-r.svg") right 15px center no-repeat;
			background-size: 5px 10px;
			padding-left: 45px;
			font-size: 13px;
		}		
	}		
	
	&__item_04{
		font-weight: 500;
		
		&:not(.open){
			@include sp{
				background: url("../images/common/plus-b.svg") right 15px center no-repeat;
				background-size: 11px;
			}
		}

		&.open{
			@include sp{
				background: url("../images/common/arrow-b-r.svg") right 15px center no-repeat;
				background-size: 5px 10px;
			}
		}	
		
		&-link{
			&::before{
				content: "ー";
				margin-right: 8px;
			}
			
			@include sp{
				border-top: 1px solid $c_gray5;
				display: block;
				padding: 14px 0;
			}
		}
		
		@include pc{
			margin-bottom: 8px;
			font-size: 13px;
		}
		
		@include sp{
			padding-left: 45px;
			font-size: 13px;
		}		
	}	
	
	&__item_05{
		& + &{
			@include pc{
				margin-left: 50px;
			}
		}
		
		@include pc{
			font-size: 13px;
		}
		
		@include sp{
			width: 50%;
			text-align: center;
			margin-bottom: 40px;
		}		
	}	
	
	&__copy{
		color: $c_white;
		background: $c_main;
		text-align: center;
		
		@include pc{
			padding: 40px 0;
			font-size: 15px;
		}
		
		@include sp{
			padding: 20px 0;
			font-size: 15px;
		}		
	}
}